<template>
  <div class="dsb__header-c">
    <div class="header-text">{{ $t('Menu.Items.Overview') }}</div>
    <div class="right-aligned">
      <div
        class="btn-group"
        role="group"
        aria-label="Basic example"
      >
        <button
          type="button"
          class="btn"
          :class="{ 'btn-primary': isSelected('activity') }"
          @click="handleActivityClick"
        >
          {{ $t('Dashboard.Logs.Activity') }}
        </button>
        <button
          type="button"
          class="btn"
          :class="{ 'btn-primary': isSelected('pipeline') }"
          @click="handlePipelineClick"
        >
          {{ $t('Dashboard.Logs.Pipeline') }}
        </button>
      </div>
      <PeriodFilterComponent
        id="period-filter-dashboard"
        ref="periodFilter"
        position="left"
        :options="periodFilterOptions"
        :maxRangeDates="maxRangeDates"
        @change="handleFilterChange"
      />
    </div>
  </div>
</template>

<script>
  import PeriodFilterComponent from '@/@core/components/period-filter-component';

  export default {
    name: 'DashboardHeader',
    components: { PeriodFilterComponent },
    props: {
      isOpen: {
        type: Boolean,
        default: false
      },
      showPipelines: {
        type: Boolean,
        default: true
      },
      maxRangeDates: {
        type: Number,
        default: 7 //Periodo de 7 dias
      }
    },
    data() {
      return {
        periodFilterOptions: [
          { value: '1', text: 'PeriodFilter.1day' },
          { value: '5', text: 'PeriodFilter.5days' },
          { value: '15', text: 'PeriodFilter.15days' },
          { value: null, text: 'PeriodFilter.Custom' }
        ],
        selectedButton: 'activity'
      };
    },
    methods: {
      isSelected(button) {
        return this.selectedButton === button;
      },
      selectButton(button) {
        this.selectedButton = button;
      },
      handleActivityClick() {
        this.selectButton('activity');
        this.$emit('showPipelines', false);
        this.$emit('fetchActivity');
      },
      handlePipelineClick() {
        this.selectButton('pipeline');
        this.$emit('showPipelines', true);
        this.$emit('fetchPipeline');
      },
      handleFilterChange(value) {
        this.$emit('filter', value);
        this.$emit('fetchActivity');
        this.$emit('fetchPipeline');
      }
    }
  };
</script>

<style lang="scss" scoped>
  .dsb__header-c {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header-text {
    color: var(--colors-text-default-neutro-30, var(--text-default-neutro-30, #4c4541));
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px;
    font-variant-numeric: lining-nums proportional-nums;
  }

  .right-aligned {
    display: flex;
    align-items: center;
  }

  .btn-group {
    border-radius: 6px;
    border: 1px solid #974900 !important;
    margin-right: 16px;
  }

  .btn {
    display: flex;
    height: 28px;
    padding: 4px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    border: transparent;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.15px;
    font-variant-numeric: lining-nums, proportional-nums;
    text-align: center;
    color: var(--Cores-Principais-Primary-40, var(--Primary-40, #974900));

    &-primary {
      background: #974900 !important;
      color: white !important;
    }

    &-secondary {
      background: white !important;
      color: #974900 !important;
    }
  }

  .period-filter-container {
    display: flex;
    align-items: center;
  }

  @media (max-width: 485px) {
    .dsb__header-c {
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
    }

    .header-text {
      align-self: flex-start;
    }

    .right-aligned {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }

    .btn-group {
      display: flex;
      align-items: flex-start;
      gap: -1px;
      align-self: stretch;
      margin: 0;
    }

    .btn {
      justify-content: center;
      align-items: center;
    }

    .period-filter-container,
    #period-filter-dashboard {
      display: flex;
      height: 28px;
      justify-content: center;
      align-items: center;
      gap: 6px;
      align-self: stretch;
      margin-top: 8px;
    }
  }
</style>
