<template>
  <div id="cardGraph">
    <div class="header">
      <div class="title">{{ $t('Dashboard.Execution.ExecutionOfActivities') }}</div>
      <div class="legends">
        <div class="success"><SuccessIcon /> {{ $t('Dashboard.Success.Success') }}</div>
        <div class="fail"><FailIcon /> {{ $t('Dashboard.Execution.Error') }}</div>
      </div>
    </div>
    <div :class="['content', isResizing ? 'loading' : '']">
      <Spinner
        v-if="isResizing"
        class="spinner_dashboard"
      />
      <ag-charts-vue
        style="height: 292px"
        v-else
        :options="options"
      ></ag-charts-vue>
    </div>
  </div>
</template>

<script>
  import { AgChartsVue } from 'ag-charts-vue';
  import SuccessIcon from '@/assets/images/icons/success-icon.svg';
  import FailIcon from '@/assets/images/icons/fail-icon.svg';
  import moment from 'moment';
  import Spinner from '@/assets/images/pages/load-icon.svg';

  export default {
    name: 'CardGraph',
    components: { AgChartsVue, SuccessIcon, FailIcon, Spinner },
    props: {
      isResizing: {
        type: Boolean
      },
      entry: {
        type: Object,
        default() {
          return {};
        }
      },
      oneDayRange: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      graphTooltip(type, text, amount, period) {
        return `
              <div class="ag-chart-tooltip-content">
                <strong>${period}</strong>

                <div class="custom-tooltip">
                  <div class="chart-rectangle ${type}"></div>
                  <span>${this.$t(text)} : ${amount}</span>
                </div>
              </div>
            `;
      },
      updateEntries() {
        let aux = [];

        this.entry.error.forEach((error, index) => {
          const success = this.entry.success[index];
          const date = index === 0 ? moment(error.date).minute(1) : error.date;

          aux.push({
            quarter: this.oneDayRange ? moment(date).format('HH:mm') : moment(date).format('DD/MM'),
            success: success !== undefined ? parseInt(success.quantity) : 0,
            fail: parseInt(error.quantity)
          });
        });

        //adiciona itens vazios no inicio e no final para que não ocorra do ultimo elemento sumir se der zoom - e depois zoom máximo
        aux = [{ quarter: '', success: 0, fail: 0 }, ...aux, { quarter: ' ', success: 0, fail: 0 }];

        this.series = aux;
        this.createChart();
      },
      createChart() {
        this.options = {
          data: this.series,
          series: [
            {
              xKey: 'quarter',
              yKey: 'success',
              marker: {
                enabled: false
              },
              fill: '#66BB6A',
              stroke: '#66BB6A',
              strokeWidth: 4,
              highlightStyle: {
                item: {
                  fill: '#66BB6A',
                  stroke: '#66BB6A'
                }
              },
              tooltip: {
                renderer: (params) =>
                  this.graphTooltip(
                    'success',
                    'Dashboard.Success.Success',
                    params.datum.success,
                    params.datum.quarter
                  )
              }
            },
            {
              xKey: 'quarter',
              yKey: 'fail',
              marker: {
                enabled: false
              },
              fill: '#EF5350',
              stroke: '#EF5350',
              strokeWidth: 4,
              highlightStyle: {
                item: {
                  fill: '#EF5350',
                  stroke: '#EF5350'
                }
              },
              tooltip: {
                renderer: (params) =>
                  this.graphTooltip(
                    'fail',
                    'Dashboard.Execution.Error',
                    params.datum.fail,
                    params.datum.quarter
                  )
              }
            }
          ],
          legend: {
            enabled: false
          },
          navigator: {
            height: 8,
            mask: {
              fill: '#FBEEE8',
              strokeWidth: 0.5,
              fillOpacity: 1
            },
            minHandle: {
              stroke: '#CFC4BE',
              width: 12,
              height: 15,
              strokeWidth: 1
            },
            maxHandle: {
              stroke: '#CFC4BE',
              width: 12,
              height: 15,
              strokeWidth: 1
            }
          }
        };
      }
    },
    watch: {
      entry() {
        this.updateEntries();
      }
    },
    created() {
      this.updateEntries();
    },
    data() {
      return {
        series: [],
        options: null
      };
    }
  };
</script>

<style lang="scss">
  .spinner_dashboard {
    width: 32px;
    height: 32px;
    circle {
      fill: #974900 !important;
    }
  }
  .loading {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ag-chart-tooltip-content {
    background-color: #362f2b !important;
    color: #ffff !important;
    border-radius: 6px !important;
  }

  .custom-tooltip {
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;

    strong {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }

    span {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }

    .chart-rectangle {
      width: 14px;
      height: 14px;

      &.success {
        background-color: #66bb6a;
      }
      &.fail {
        background-color: #ef5350;
      }
    }
  }

  #cardGraph {
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0px 4px 24px 0px rgba(58, 46, 38, 0.1);

    .header {
      padding: 16px 16px 0;
      display: flex;
      flex-direction: column;
      gap: 8px;

      .title {
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 26px;
        color: #4c4541;
      }

      .legends {
        display: flex;
        gap: 14px;
        .success,
        .fail {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          display: flex;
          align-items: center;
          gap: 6px;
          color: #998f8a;
        }
      }
    }

    .content {
      min-height: 0 !important;
    }
  }

  @media (max-width: 425px) {
    .ag-chart-wrapper {
      height: 292px !important;
    }
  }
</style>
