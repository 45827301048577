<template>
  <div id="cardSuccess">
    <div class="header">
      <div class="textBlock">
        <div class="success">
          {{ $t('Dashboard.Success.Success') }}
        </div>
      </div>
      <div class="quantity">{{ total }}</div>
    </div>
    <div :class="['content', isResizing ? 'loading-card' : '']">
      <Spinner
        v-if="isResizing"
        class="spinner_dashboard"
      />
      <div
        v-else
        id="chart"
      >
        <apexcharts
          height="61"
          type="area"
          :options="chartOptions"
          :series="series"
        ></apexcharts>
      </div>
    </div>
  </div>
</template>

<script>
  import VueApexCharts from 'vue-apexcharts';
  import Spinner from '@/assets/images/pages/load-icon.svg';

  export default {
    name: 'CardSuccess',
    components: { apexcharts: VueApexCharts, Spinner },
    props: {
      isResizing: {
        type: Boolean
      },
      total: {
        type: String,
        default: ''
      },
      entry: {
        type: Array,
        default() {
          return [];
        }
      }
    },
    methods: {
      updateEntries() {
        this.series = [
          {
            name: 'Success',
            data: this.entry.map((item) => {
              return parseInt(item.quantity);
            })
          }
        ];
      }
    },
    mounted() {
      this.updateEntries();
    },
    data() {
      return {
        chartOptions: {
          chart: {
            id: 'success-area',
            sparkline: {
              enabled: true
            },
            width: '100%'
          },
          fill: {
            colors: ['#00CB65'],
            type: 'gradient',
            gradient: {
              opacityFrom: 0.6,
              opacityTo: 0
            }
          },
          stroke: {
            colors: ['#A5D6A7'],
            curve: 'straight'
          }
        },
        series: []
      };
    },
    watch: {
      entry() {
        this.updateEntries();
      }
    }
  };
</script>

<style lang="scss">
  .loading-card {
    height: 61px !important;
    display: flex;
    justify-content: center;

    svg {
      position: absolute;
    }
  }
</style>

<style lang="scss" scoped>
  #cardSuccess {
    width: 100%;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0px 4px 24px 0px rgba(58, 46, 38, 0.1);
    max-width: 100%;
    .header {
      display: flex;
      justify-content: space-between;
      padding: 16px;
      .textBlock {
        display: flex;
        align-items: center;
        gap: 4px;
        height: 25px;

        svg {
          transform: scale(0.875);
        }

        .success {
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 26px;
          color: #4c4541;
        }
      }
      .quantity {
        height: 25px;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 31px;
        color: #4c4541;
      }
    }
  }

  @media (max-width: 480px) {
    #cardSuccess {
      max-width: 100%;
    }
  }
</style>
