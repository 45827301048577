<template>
  <div id="integrationLogs">
    <div
      class="card-integration-log"
      v-for="(item, index) in listagemPipelines"
      :key="index"
      @click="redirectToLogCenter(item)"
    >
      <div :class="statusClass(item.status)">{{ item.status }}</div>
      <div class="pipeline">
        <div class="label">{{ $t('Dashboard.Logs.Pipeline') }}</div>
        <div class="action --ellipsis">{{ item.pipeline }}</div>
      </div>
      <div class="dateTime">
        <div class="label">{{ $t('Dashboard.Logs.DateTime') }}</div>
        <div class="action --ellipsis">{{ formatDateTime(item.start_date) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment';

  export default {
    data() {
      return {
        listagemPipelines: []
      };
    },
    props: {
      listagem: {
        type: Array
      }
    },
    methods: {
      redirectToLogCenter(item) {
        this.$emit('redirect', 'log-center', item);
      },
      statusClass(status) {
        return {
          status: true,
          notIntegrated: status === 'Not Integrated',
          integrated: status !== 'Not Integrated'
        };
      },
      formatDateTime(date) {
        if (!date) return '';
        const formattedDate = moment(date).format('YYYY-MM-DD HH:mm');
        return formattedDate;
      }
    },
    watch: {
      listagem(newVal) {
        this.listagemPipelines = newVal.slice(-5);
      }
    }
  };
</script>

<style lang="scss">
  #integrationLogs {
    .card-integration-log {
      border-radius: 6px;
      border: 1px solid #eee;
      padding: 12px 8px;
      background: #fff;
      cursor: pointer;
      margin-bottom: 4px;

      &:last-child {
        margin-bottom: 11px !important;
      }

      &:hover {
        background: #974900;

        .pipeline,
        .dateTime {
          .label,
          .action {
            color: #fff;
          }
        }
      }

      .--ellipsis {
        display: block;
        overflow: hidden;
        white-space: nowrap;
        word-break: break-all;
        text-overflow: ellipsis;
        max-width: 100%;
      }

      .status {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        border-radius: 4px;
      }

      .notIntegrated {
        background-color: #ffebee;
        color: #f44336;
      }

      .integrated {
        background-color: #e8f5e9;
        color: #4caf50;
      }

      .pipeline {
        padding-top: 6px;
      }

      .dateTime {
        padding-top: 4px;
      }

      .pipeline,
      .dateTime {
        .label,
        .action {
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          color: #998f8a;
        }

        .action {
          font-weight: 600;
          color: #4c4541;
        }
      }
    }
  }
</style>
