<template>
  <div id="cardExecution">
    <div class="title add-ellipsis">{{ $t('Dashboard.Execution.ExecutionOfActivities') }}</div>
    <div class="content">
      <div class="runtimeBlock">
        <div class="runtime">{{ time }}</div>
        <div class="description">{{ $t('Dashboard.Execution.Time') }}</div>
      </div>
      <div class="quantityBlock">
        <div class="quantity">{{ total }}</div>
        <div class="description">{{ $t('Dashboard.Execution.Quantity') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'CardExecution',
    props: {
      time: {
        type: String,
        default: ''
      },
      total: {
        type: String,
        default: ''
      }
    },
    components: {},
    data() {
      return {};
    }
  };
</script>

<style lang="scss" scoped>
  #cardExecution {
    width: 100%;
    padding: 16px;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0px 4px 24px 0px rgba(58, 46, 38, 0.1);
    max-width: 100%;
    .add-ellipsis {
      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .title {
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 26px;
      color: #4c4541;
    }
    .content {
      display: flex;
      justify-content: center;
      gap: 24px;
      .runtimeBlock,
      .quantityBlock {
        width: 100%;
        text-align: center;
        .quantity,
        .runtime {
          font-size: 36px;
          font-style: normal;
          font-weight: 400;
          line-height: 44px;
          color: #4c4541;
        }
        .description {
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          color: #998f8a;
        }
      }
    }
  }
  @media (max-width: 768px) and (min-width: 480px) {
    #cardExecution {
      .content {
        gap: 32px;
        .runtimeBlock,
        .quantityBlock {
          max-width: 151px;
        }
      }
    }
  }
  @media (max-width: 480px) {
    #cardExecution {
      max-width: 100%;
      .title {
        font-size: 18px;
      }
      .content {
        .quantityBlock,
        .runtimeBlock {
          .quantity,
          .runtime {
            font-size: 26px;
          }
        }
      }
    }
  }
</style>
