<template>
  <div class="dashboard-page">
    <b-row>
      <b-col lg="12">
        <Header
          @filter="setFilter"
          :showPipelines="showPipelines"
          :maxRangeDates="30"
          @showPipelines="handleShowPipelines"
          @fetchPipeline="getPipeline"
          @fetchActivity="getActivity"
        />
      </b-col>
    </b-row>

    <div :class="['dashboard-content', { open: isOpen }]">
      <div class="info-cards-container">
        <CardExecution
          class="card-execution tiny-card"
          :time="dashboardData.time"
          :total="dashboardData.total"
        />

        <CardSuccess
          class="card-success tiny-card"
          :total="dashboardData.success"
          :entry="dashboardData.dashboard.success"
          :isResizing="isResizing"
        />

        <CardFailure
          class="card-failure tiny-card"
          :total="dashboardData.error"
          :entry="dashboardData.dashboard.error"
          :isResizing="isResizing"
        />
      </div>

      <CardLog
        class="card-log-c"
        :showPipelines="showPipelines"
        :activityList="activityList"
        :pipelineList="pipelineList"
        :filter="filter"
        :isOpen="isOpen"
        @showPipelines="handleShowPipelines"
        @fetchPipeline="getPipeline"
        @fetchActivity="getActivity"
      />

      <CardGraph
        class="card-graph"
        :entry="dashboardData.dashboard"
        :oneDayRange="oneDayRange"
        :isResizing="isResizing"
      />
    </div>
  </div>
</template>

<script>
  import { mapMutations } from 'vuex';
  import { BCol, BRow } from 'bootstrap-vue';
  import Header from './components/header';
  import CardExecution from './components/card-execution/index.vue';
  import CardFailure from './components/card-failure/index.vue';
  import CardSuccess from './components/card-success/index.vue';
  import CardGraph from './components/card-graph-execution/index.vue';
  import CardLog from './components/card-logs/LastLogs.vue';
  import moment from 'moment';

  export default {
    components: {
      BCol,
      BRow,
      Header,
      CardExecution,
      CardFailure,
      CardSuccess,
      CardGraph,
      CardLog
    },
    data() {
      return {
        isOpen: false,
        isResizing: false,
        dashboardData: {
          time: '',
          total: '',
          success: '0',
          error: '',
          dashboard: {
            error: [],
            success: []
          }
        },
        currentSite: this.$cookies.get('userInfo').currentSite,
        isMobile: window.innerWidth <= 768,
        filter: {
          startDate: '',
          endDate: '',
          days: 1
        },
        oneDayRange: true,
        showPipelines: false,
        activityList: [],
        pipelineList: []
      };
    },
    mounted() {
      this.getDashboardData();
      this.getPipeline();
      this.createSidebarObserver();
    },
    methods: {
      ...mapMutations('app', ['UPDATE_FLAG_SHOW_OVERLAY']),
      handleShowPipelines(value) {
        this.showPipelines = value;
      },
      createSidebarObserver() {
        const sidebar = document.getElementById('menu-bar');

        if (!sidebar) return;

        const observer = new MutationObserver((mutationsList) => {
          for (const mutation of mutationsList) {
            if (mutation.attributeName === 'class') {
              this.isResizing = true;
              this.isOpen = sidebar.classList.contains('open');
              setTimeout(() => {
                this.isResizing = false;
              }, 600);
            }
          }
        });

        observer.observe(sidebar, { attributes: true });
      },
      clearFilter() {
        this.filter = {
          startDate: '',
          endDate: '',
          days: 0
        };
      },
      formatDate(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      },
      setFilter({ option, datepicker }) {
        this.clearFilter();
        let oneDayFlag = false;

        if (option !== null) {
          this.filter.days = option !== 'all' ? option : 0;
          oneDayFlag = option == 1;
        } else {
          this.filter.startDate = moment(new Date(datepicker[0])).format('YYYY-MM-DD');
          this.filter.endDate = moment(new Date(datepicker[1])).format('YYYY-MM-DD');
        }

        this.oneDayRange = oneDayFlag;

        this.getDashboardData();
        this.showPipelines ? this.getPipeline() : this.getActivity();
      },
      getDashboardData() {
        this.UPDATE_FLAG_SHOW_OVERLAY(true);
        this.$http
          .get(
            `/api/${this.$router.currentRoute.params.prefix}/mydashboard?filterDays=${
              this.filter.days
            }&startDate=${this.filter.startDate}&endDate=${
              this.filter.endDate
            }&site_guid=${'A860E63D-522D-4B6F-9687-DFBEFAE3C38E'}`
          )
          .then((res) => {
            this.dashboardData = res.data;
            this.refreshing = false;
            this.UPDATE_FLAG_SHOW_OVERLAY(false);
          });
      },
      getActivity() {
        this.UPDATE_FLAG_SHOW_OVERLAY(true);
        this.$http
          .get(
            `api/${
              this.$router.currentRoute.params.prefix
            }/service-log?site_guid=${'A860E63D-522D-4B6F-9687-DFBEFAE3C38E'}&filterDays=${
              this.filter.days
            }&startDate=${this.filter.startDate}&endDate=${this.filter.endDate}`
          )
          .then((res) => {
            this.activityList = res.data.response.slice(-20);
            this.UPDATE_FLAG_SHOW_OVERLAY(false);
          });
      },
      getPipeline() {
        this.UPDATE_FLAG_SHOW_OVERLAY(true);
        this.$http
          .get(
            `/api/${
              this.$router.currentRoute.params.prefix
            }/event_script/pipelines/log?site_guid=${'A860E63D-522D-4B6F-9687-DFBEFAE3C38E'}&filterDays=${
              this.filter.days
            }&startDate=${this.filter.startDate}&endDate=${this.filter.endDate}`
          )
          .then((res) => {
            this.pipelineList = res.data.slice(-20);
            this.UPDATE_FLAG_SHOW_OVERLAY(false);

            const loadingMyConnect = this.$cookies.get('modalNoSites');
            const hasSites = this.$cookies.get('userInfo').userData.sites;

            if (hasSites.length === 0 && (!loadingMyConnect || !loadingMyConnect.myconnect)) {
              this.$swal({
                title: this.$t('Dashboard.WarningTitle'),
                text: this.$t('Dashboard.WarningText'),
                showCancelButton: false,
                showConfirmButton: false,
                showCloseButton: true,
                customClass: {
                  container: 'swal-warning',
                  confirmButton: 'btn btn-primary',
                  cancelButton: 'btn btn-outline-danger ml-1'
                }
              });
              const domain = window.location.href.includes('indsiders.com');
              this.$cookies.set(
                'modalNoSites',
                { mysfc: false, myconnect: true },
                null,
                null,
                domain ? 'indsiders.com' : null
              );
            }
          });
      }
    }
  };
</script>

<style lang="scss">
  .dashboard-page {
    transition: none;

    &.open {
      width: calc(100% - 5px);
    }

    .info-cards-container {
      grid-area: infos;
      display: flex;
      gap: 24px;

      .card-execution,
      .card-success,
      .card-failure {
        min-width: calc((100% - 24px - 24px) / 3);
      }
    }

    .card-log-c {
      grid-area: log;
    }

    .card-execution {
      grid-area: execution;
    }

    .card-success {
      grid-area: success;
    }

    .card-failure {
      grid-area: failure;
    }

    .card-graph {
      grid-area: graph;
    }

    .dashboard-content {
      display: grid;
      gap: 24px 30px;
      grid-template:
        'infos log'
        'graph log'
        / minmax(74.31%, 74.31%) minmax(22.85%, 22.85%);
    }
  }

  .swal-warning {
    .swal2-modal {
      padding: 0 !important;
      border-radius: 6px;
      width: 710px;

      .swal2-header {
        background-color: #eceff1;
        padding: 20px 16px;
        border-radius: 6px 6px 0px 0px;
        flex-direction: row;
        justify-content: left;

        .swal2-title {
          color: #4c4541;
          font-size: 18px;
          font-weight: 600;
          line-height: 26px;
          margin: 0;
        }

        .swal2-close {
          border-radius: 6px;
          width: 34px;
          background-color: #ffff;
          height: 34px;
          top: -5px;
          position: absolute;
          right: -5px;
          padding-bottom: 3px;

          &:hover {
            color: #4c4541;
          }
        }
      }

      .swal2-content {
        padding: 20px 16px;

        .swal2-html-container {
          font-family: 'Raleway';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #4c4541;
          display: flex !important;
          text-align: left;
        }
      }

      .swal2-actions {
        padding: 0 16px 20px 16px;
        display: flex;
        justify-content: right;

        .swal2-confirm {
          margin-left: 10px !important;
          order: 2;
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 600;
          font-size: 17px;
          line-height: 20px;
          color: #ffff;
          border-color: #974900 !important;
          background-color: #974900 !important;
          border-radius: 5px;

          &:hover {
            box-shadow: none !important;
          }
        }

        .swal2-cancel {
          order: 1;
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 600;
          font-size: 17px;
          line-height: 20px;
          color: #974900 !important;
          border-radius: 5px;
          border-color: transparent !important;

          &:hover {
            background-color: #ffede1 !important;
          }
        }
      }
    }
  }

  @media (max-width: 1215px) and (min-width: 672px) {
    .dashboard-page {
      .dashboard-content {
        grid-template:
          'infos log'
          'graph graph'
          / minmax(56.96%, 56.96%) minmax(39.68%, 39.68%);
      }

      .info-cards-container {
        flex-direction: column;
        width: 100%;
      }
    }
  }

  @media (max-width: 1866px) and (min-width: 901px) {
    .dashboard-page {
      .dashboard-content {
        &.open {
          grid-template:
            'infos log'
            'graph graph'
            / minmax(56.96%, 56.96%) minmax(39.68%, 39.68%) !important;

          .info-cards-container {
            flex-direction: column !important;
            width: 100% !important;
          }
        }
      }
    }
  }

  @media (max-width: 900px) {
    .dashboard-page {
      .dashboard-content {
        &.open {
          grid-template:
            'infos'
            'log'
            'graph'
            / minmax(100%, 100%) minmax(100%, 100%) !important;

          .info-cards-container {
            flex-direction: column !important;
            width: 100% !important;
          }
        }
      }
    }
  }

  @media (max-width: 672px) {
    .dashboard-page {
      .dashboard-content {
        grid-template:
          'infos'
          'log'
          'graph'
          / minmax(100%, 100%) minmax(100%, 100%);

        .info-cards-container {
          flex-direction: column;
          width: 100%;
        }
      }
    }
  }
</style>
